import { createApp } from 'vue'
import App from './views/pc/front/App.vue'
import router from './router'
import store from './store'
import axiosInit from './network/axios-init';
import plugin from './utils/plugin'
import ElementPlus from 'element-plus'
import config from './lib/config/config'
import locale from 'element-plus/lib/locale/lang/zh-cn' //中文
// import 'element-plus/lib/theme-chalk/index.css'
import SliderVerify from 'slider-verify-v3'
import 'slider-verify-v3/lib/SliderVerify.css'
import './assets/css/theme/blue.scss'
import 'element-plus/lib/theme-chalk/el-icon.css'
import 'assets/font/iconfont/iconfont.css'
import 'assets/css/normalize.css'
import 'assets/css/common.scss'
import storage from "./utils/storage";

const app = createApp(App)

app.config.globalProperties.$http = axiosInit()
storage.sessionSet('platform', 'pc')

app.use(store)
app.use(router)
app.use(ElementPlus, { locale })
app.use(plugin)
app.use(SliderVerify)
app.use(config)
app.mount('#app')
